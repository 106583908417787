import React, { useCallback, useEffect, useState } from 'react';
import { Attach, Back, Citation, Image, Submit } from '../assets';
import DetailsComponent from '../components/DetailsComponent';
import Button from '../components/Button';
import { useLocation, useNavigate } from 'react-router-dom';
import { useLazyQuery, useMutation } from '@apollo/client';
import { CONTEST_CONTACT_US } from '../apis';
import { Toasts } from '../components/Toasts';
import { GET_CITATION_PERMITS } from './TicketDetails';
import { generateAddress, isValidEmail } from '../util/helperFunctions';
import { ClipLoader } from 'react-spinners';
import AppInput from '../components/AppInput';

const ContestCitation = () => {
 const navigate = useNavigate();
 const [value, setValue] = useState();
 const location = useLocation();
 const queryParams = new URLSearchParams(location.search);
 const citationNumber = queryParams.get('citation-number');
 const [contactUs] = useMutation(CONTEST_CONTACT_US);
 const [getCitationPermits] = useLazyQuery(GET_CITATION_PERMITS, {
  fetchPolicy: 'network-only',
 });
 const [loading, setLoading] = useState(false);
 const [file, setFile] = useState(null);
 const [municipality, setMunicipality] = useState();
 const [data, setData] = useState();
 const [messageError, setMessageError] = useState();
 const [email, setEmail] = useState();
 const [emailError, setEmailError] = useState();

 const handleFileChange = (e) => {
  const selectedFile = e.target.files[0];
  setFile(selectedFile);
 };

 const handleFileRemove = useCallback(() => {
  setFile(null); // Remove the file
 }, []);

 const handleSearchData = useCallback(async () => {
  try {
   setLoading(true);
   const { data } = await getCitationPermits({
    variables: {
     citationNumber: Number(citationNumber),
    },
   });

   const currentCitations = data?.getCitationPermits.citations?.find(
    (citation) => citation?.citationNumber === Number(citationNumber),
   );
   setData(currentCitations);
   if (data?.getCitationPermits?.citations?.[0]) {
    setMunicipality(data?.getCitationPermits?.citations?.[0]?.municipality);
   } else if (data?.getCitationPermits?.permits?.[0]) {
    setMunicipality(data?.getCitationPermits?.permits?.[0]?.municipality);
   }
  } catch (error) {
   console.log('>>>', error);
  } finally {
   setLoading(false);
  }
 }, [citationNumber, getCitationPermits]);
 useEffect(() => {
  if (citationNumber) {
   handleSearchData();
  }
 }, [citationNumber, handleSearchData]);

 const handleContestSubmission = useCallback(async () => {
  if (!value && !email) {
   setMessageError('Contest reason is required.');
   setEmailError('Email is required');
   return;
  }
  if (!value) {
   setMessageError('Contest reason is required.');
   return;
  }
  if (!email) {
   setEmailError('Email is required');
   return;
  }
  if (!isValidEmail(email)) {
   setEmailError('Please enter a valid email.');
   return;
  }
  try {
   await contactUs({
    variables: {
     createContactUs: { citationId: data?.id, message: value, email: email },
    },
   });
   Toasts({ variant: 'success', message: 'Citation contested successfully' });
   navigate(-1);
  } catch (error) {
   console.error(error);
  }
 }, [contactUs, data?.id, email, navigate, value]);

 return loading ? (
  <div className="flex flex- h-screen justify-center items-center">
   <ClipLoader />
  </div>
 ) : (
  <>
   <div className="py-[30px]">
    <div className="flex justify-center items-start pb-8">
     {municipality?.logo && (
      <img
       className="align-center w-[156px] h-[156px] object-cover"
       src={municipality?.logo}
       alt="Municipality-logo"
      />
     )}
    </div>
    <div className="flex justify-center items-center w-full">
     <div className=" flex-1 flex-col justify-start max-w-[373px] md:max-w-[429px] ">
      <div className="flex flex-col justify-start items-start gap-[18px]">
       <div className="flex flex-col justify-start items-start gap-3">
        <p className="text-xs font-semibold">Owner:</p>
        <p className="text-black text-opacity-70">John Hancock</p>
       </div>
       <div className="flex flex-col justify-start items-start gap-3">
        <p className="text-xs font-semibold">Owner’s Address:</p>
        <p className="text-black text-opacity-70">
         04914 Murray Lights Apt. 037, Fort Collins, NC, 21085
        </p>
       </div>
       <div className="border-b w-full"></div>
       <div className="flex flex-col justify-start items-start gap-3">
        <p className="text-xs font-semibold">Vehicle:</p>
        <p className="text-black text-opacity-70">
         {data?.color || data?.make || data?.modal
          ? `${data?.color || ''} ${data?.make || ''} ${data?.modal || ''}`
          : '---'}
        </p>
       </div>
       <DetailsComponent
        titleLeft="License Plate: "
        descriptionLeft={data?.licensePlateNumber}
        titleRight="State:"
        descriptionRight={data?.licensePlateState}
       />
       <DetailsComponent
        titleLeft="Status:  "
        descriptionLeft="Active"
        titleRight="Reg. Expiring:"
        descriptionRight="09/21/2024"
       />
       <div className="flex flex-col justify-start items-start gap-3">
        <p className="text-xs font-semibold">Registration Address:</p>
        <p className="text-black text-opacity-70">
         04914 Murray Lights Apt. 037, Fort Collins, NC, 21085
        </p>
       </div>
       <div className="border-b w-full"></div>
       <DetailsComponent
        titleLeft="Date/Time: "
        descriptionLeft={data?.date || '---'}
        titleRight="Type of Violation:"
        descriptionRight={data?.violation?.title}
       />
       <DetailsComponent
        titleLeft="Status: "
        descriptionLeft={
         data?.citationStatus === 'PENDING' ? 'Not Paid' : data?.citationStatus
        }
        titleRight="Amount Owed:"
        descriptionRight={data?.fineAmount}
       />
       <DetailsComponent
        titleLeft="Citation #:"
        descriptionLeft={data?.citationNumber}
        titleRight="Evidence:"
        descriptionRight={
         data?.photo ? (
          <a href={data?.photo} target="_blank" rel="noopener noreferrer">
           <div className="flex justify-start items-center gap-2">
            <Image />
            <p className="text-xs text-black text-opacity-70">evidence.png</p>
           </div>
          </a>
         ) : (
          '---'
         )
        }
       />
       <div className="flex flex-col justify-start items-start gap-3">
        <p className="text-xs font-semibold">Address of Violation:</p>
        <p className="text-black text-opacity-70">
         {data?.location?.address ||
          generateAddress(data?.locationResult) ||
          '---'}
        </p>
       </div>
       <div className="border-b w-full"></div>
       <div>
        <div className="flex  justify-start items-center gap-2 pb-[16px] pt-[18px]">
         <Citation />
         <p className="text-lg font-medium">Contest Citation</p>
        </div>
        <p className="text-xs text-black text-opacity-70">
         Please enter an explanation for why you’re contesting this citation.
        </p>
       </div>
       <div className="w-full">
        <AppInput
         value={email}
         type="text"
         placeholder="Your Email"
         onChange={(e) => {
          setEmailError('');
          setEmail(e?.target?.value);
         }}
        />
        <div className="h-5 mt-1">
         {emailError && (
          <p className="text-red-500 text-xs ">{emailError || ''}</p>
         )}
        </div>
        <div className="relative flex flex-col w-full">
         <textarea
          id="message"
          name="message"
          rows="4"
          value={value?.message}
          onChange={(e) => {
           setMessageError('');
           setValue(e.target.value);
          }}
          placeholder="Contest reason"
          className={`font-normal bg-white placeholder:font-normal placeholder:text-sm border w-full hover:border-black hover:border-opacity-30 focus:border-black focus:border-opacity-30 caret-primary05 outline-none rounded-md py-2 pr-10 ${
           value?.message ? 'pt-3 pb-1' : 'py-2'
          }`}
         />
         {value?.message && (
          <label className="absolute text-[11px] ml-3 text-[rgba(0,0,0,0.5)] self-start">
           {'Contest reason'}
          </label>
         )}
        </div>

        <div className="h-5">
         {messageError && (
          <p className="text-red-500 text-xs ">{messageError || ''}</p>
         )}
        </div>
       </div>

       {/* File Upload Section */}
       <div className="flex flex-col gap-4 w-full mt-4">
        {/* Show file name with "x" icon if a file is uploaded */}
        {file && (
         <div className="flex items-center justify-between border p-2 rounded">
          <span className="text-sm">{file.name}</span>
          <button
           type="button"
           className="text-red-500 text-lg"
           onClick={handleFileRemove}
          >
           &times;
          </button>
         </div>
        )}

        {/* File upload input */}
       </div>
       <div className="flex justify-between items-center w-full gap-4">
        <Button
         name={'Submit'}
         onClick={handleContestSubmission}
         size="small"
         fullWidth={true}
         variant="primary"
         svg={<Submit />}
         svgBefore={true}
         customClass=" flex-1 !tracking-[2px] w-full "
        />

        <input
         type="file"
         id="file-upload"
         style={{ display: 'none' }}
         onChange={handleFileChange}
        />

        {/* Trigger file input click via button */}
        <Button
         name={'Attach File'}
         size="small"
         fullWidth={true}
         variant="secondary"
         svg={<Attach />}
         svgBefore={true}
         customClass="flex-1 !tracking-[2px] w-full"
         onClick={() => document.getElementById('file-upload').click()} // Trigger file input click
        />
       </div>
      </div>
     </div>
    </div>
   </div>
   <div className="fixed top-0 left-0 cursor-pointer w-screen md:w-fit bg-[#FAFAFA] py-[37px] px-[30px]">
    <div
     className="flex justify-start items-center gap-[10px]"
     onClick={() => navigate(-1)}
    >
     <Back />
     <p className="font-bold">Back</p>
    </div>
   </div>
  </>
 );
};

export default ContestCitation;
