export const generateAddress = (addressObj) => {
  if (!addressObj) {
    return '';
  }
  const { streetNumber, street, district, city, subregion, region, postalCode, country } =
    addressObj;

  // Format the address by concatenating non-empty fields
  const address = [
    streetNumber && streetNumber, // Street Number (optional)
    street && street, // Street (optional)
    district && `, ${district}`, // District (optional)
    city && `, ${city}`, // City (optional)
    subregion && `, ${subregion}`, // Subregion (optional, like a county)
    region && `, ${region}`, // Region or State (optional)
    postalCode && `, ${postalCode}`, // Postal Code (optional)
    country && `, ${country}`, // Country (optional)
  ]
    .filter(Boolean) // Remove undefined or empty fields
    .join(''); // Join all fields into a single string

  return address;
};

export const isValidEmail = (email) => {
  const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
  return emailRegex.test(email);
};

export function formatDate(inputDate) {
  if (!inputDate) return '';

  const date = new Date(inputDate);

  if (isNaN(date.getTime())) return ''; // Invalid date check

  const month = (date.getMonth() + 1).toString().padStart(2, '0'); // Ensure two digits
  const day = date.getDate().toString().padStart(2, '0');
  const year = date.getFullYear();

  return `${month}/${day}/${year}`;
}

export const formatStatus = (status) => {
  if (!status) return '';
  return status
    .toLowerCase()
    .replace(/_/g, ' ') // Replace underscores with spaces
    .replace(/\b\w/g, (char) => char.toUpperCase()); // Capitalize first letter of each word
};

export const encodeMunicipalityName = (name) => {
  return encodeURIComponent(name);
};
