import { Navigate, Route, Routes } from 'react-router-dom';
import MainLayout from './MainLayout';
import SearchTicket from '../pages/SearchTicket';
import TicketDetails from '../pages/TicketDetails';
import ContestCitation from '../pages/ContestCitation';
import PayCitation from '../pages/PayCitation';
import ContactUs from '../pages/ContactUs';
import PaymentSuccess from '../pages/PaymentSuccess';
import PaymentError from '../pages/PaymentError';
import CancelPage from '../pages/CancelPage';
import HomePage from '../pages/HomePage';
import OptionsPage from '../pages/OptionsPage';
import PermitEmailPage from '../pages/PermitEmailPage';
import EmailValidationPage from '../pages/EmailValidationPage';
import PermitsListingPage from '../pages/PermitsListingPage';
import PayPermit from '../pages/PayPermit';

const Router = () => {
 return (
  <Routes>
   {/* Redirect to default municipality selection */}
   <Route path="/" element={<HomePage />} />

   <Route path="/:municipalityName" element={<MainLayout />}>
    <Route index element={<OptionsPage />} />
    <Route path="search-citation" element={<SearchTicket />} />
    <Route path="contact-us" element={<ContactUs />} />
    <Route path="ticket" element={<TicketDetails />} />
    <Route path="permits" element={<PermitsListingPage />} />
    <Route path="pay-permit" element={<PayPermit />} />
    <Route path="contest-citation" element={<ContestCitation />} />
    <Route path="pay-citation" element={<PayCitation />} />
    <Route path="permit-email" element={<PermitEmailPage />} />
    <Route path="email-validation" element={<EmailValidationPage />} />
    <Route path="success" element={<PaymentSuccess />} />
    <Route path="error" element={<PaymentError />} />
    <Route path="cancel" element={<CancelPage />} />
    {/* <Route path="options" element={<OptionsPage />} /> */}
   </Route>

   {/* Catch-all for unknown municipality routes */}
   <Route path="*" element={<Navigate to="/" />} />
  </Routes>
 );
};

export default Router;
