import React from 'react';

const AppInput = ({
 type = 'text',
 placeholder = 'Enter text',
 value,
 onChange,
 className = '',
 ...props
}) => {
 return (
  <>
   {value && (
    <label className="absolute text-[11px] ml-3 text-[rgba(0,0,0,0.5)] self-start">
     {placeholder}
    </label>
   )}
   <input
    type={type}
    placeholder={placeholder}
    value={value}
    onChange={onChange}
    className={`flex-1 font-normal bg-white placeholder:font-normal placeholder:text-sm border w-full border-neutral03 border-opacity-30 hover:border-black hover:border-opacity-100 focus:border-black focus:border-opacity-100 caret-primary05 outline-none rounded-md ${className} ${
     value ? 'pt-3 pb-1' : 'py-2'
    }`}
    {...props}
   />
  </>
 );
};

export default AppInput;
