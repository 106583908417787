import { gql } from '@apollo/client';

export const CONTEST_CONTACT_US = gql`
 mutation contactUs($createContactUs: CreateContactInput!) {
  contactUs(createContactUs: $createContactUs) {
   success
   message
  }
 }
`;

export const GET_MUNICIPALITY_PUBLIC_DATA = gql`
 query GetMunicipalityPublicData($municipalityName: String!) {
  getMunicipalityPublicData(municipalityName: $municipalityName) {
   name
   logo
   id
  }
 }
`;

export const GET_PERMITS_BY_EMAIL = gql`
 query GetPermitsByOwnerEmail($ownerEmail: String!, $municipalityId: String!) {
  getPermitsByOwnerEmail(
   ownerEmail: $ownerEmail
   municipalityId: $municipalityId
  ) {
   id
  }
 }
`;

export const GET_PERMITS_BY_EMAIL_WITH_DETAIL = gql`
 query GetPermitsByOwnerEmail($ownerEmail: String!, $municipalityId: String!) {
  getPermitsByOwnerEmail(
   ownerEmail: $ownerEmail
   municipalityId: $municipalityId
  ) {
   id
   permitNumber
   color
   licensePlateNumber
   licensePlateState
   make
   modal
   fee
   ownerCity
   ownerAddress
   ownerName
   term
   ownerZip
   ownerState
   ownerDOB
   effectiveDate
   expirationDate
   permitStatus
   municipalityId
   parkingPermitId
   renewable
   paidByStripe
   customerId
   customerType
   ownerEmail
   ownerPhone
   comments
   subscriptionId
   subscriptionCanceled
   lastPayment
   nextPayment
   createdAt
   updatedAt
  }
 }
`;

export const CREATE_PAYMENT_SESSION = gql`
 mutation CreateCheckoutSession(
  $citationId: String
  $permitId: String
  $successUrl: String
  $cancelUrl: String
 ) {
  createCheckoutSession(
   createCheckoutSessionInput: {
    citationId: $citationId
    permitId: $permitId
    successUrl: $successUrl
    cancelUrl: $cancelUrl
   }
  )
 }
`;

export const GET_PERMIT_BY_ID = gql`
 query GetPermitById($id: String!) {
  getPermitById(id: $id) {
   id
   permitNumber
   color
   licensePlateNumber
   licensePlateState
   make
   modal
   fee
   ownerCity
   ownerAddress
   ownerName
   term
   ownerZip
   ownerState
   ownerDOB
   effectiveDate
   expirationDate
   permitStatus
   municipalityId
   parkingPermitId
   renewable
   paidByStripe
   customerId
   customerType
   ownerEmail
   ownerPhone
   comments
   subscriptionCanceled
   lastPayment
   nextPayment
   municipality {
    id
    name
    logo
   }
  }
 }
`;

export const CREATE_SUBSCRIPTION_SESSION = gql`
 mutation CreateSubscriptionSession(
  $cancelUrl: String!
  $successUrl: String!
  $permitId: String!
 ) {
  createSubscriptionSession(
   input: {
    cancelUrl: $cancelUrl
    successUrl: $successUrl
    permitId: $permitId
   }
  ) {
   id
   url
  }
 }
`;

export const CANCEL_SUBSCRIPTION = gql`
 mutation CancelSubscription($subscriptionId: String!) {
  cancelSubscription(subscriptionId: $subscriptionId)
 }
`;

export const SEND_OTP = gql`
 mutation SendOtp($email: String!, $municipalityId: String!) {
  sendOtp(email: $email, municipalityId: $municipalityId)
 }
`;

export const VERIFY_OTP = gql`
 mutation VerifyOtp($email: String!, $otp: String!) {
  verifyOtp(email: $email, otp: $otp)
 }
`;
