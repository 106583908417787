import React from 'react';

const Modal = ({ isOpen, onClose, children }) => {
 if (!isOpen) return null;

 return (
  <>
   <div
    className="fixed inset-0 bg-black bg-opacity-50 z-50"
    onClick={onClose}
   ></div>

   {/* Modal content */}
   <div className="fixed inset-0 flex items-center justify-center z-50">
    <div
     className="bg-[#EDEDED] p-9 rounded-md w-[571px]"
     onClick={(e) => e.stopPropagation()} // Prevent closing when clicking inside the modal
    >
     {children}
    </div>
   </div>
  </>
 );
};

export default Modal;
