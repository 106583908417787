import { useQuery } from '@apollo/client';
import React, { useEffect } from 'react';
import { Back, Car, Receipt } from '../assets';
import { useNavigate, useParams } from 'react-router-dom';
import { GET_MUNICIPALITY_PUBLIC_DATA } from '../apis';
import { ClipLoader } from 'react-spinners';

const OptionsPage = () => {
 const navigate = useNavigate();
 const { municipalityName } = useParams();
 const { data, loading } = useQuery(GET_MUNICIPALITY_PUBLIC_DATA, {
  variables: { municipalityName: municipalityName },
  skip: !municipalityName,
 });
 useEffect(() => {
  if (
   !loading &&
   (!data?.getMunicipalityPublicData ||
    data?.getMunicipalityPublicData.length === 0)
  ) {
   navigate('/');
  }
 }, [data, loading, navigate]);
 const handleNavigation = (path) => {
  navigate(`/${municipalityName}/${path}`);
 };
 return loading ? (
  <div className="flex flex- h-screen justify-center items-center">
   <ClipLoader />
  </div>
 ) : (
  <div className="flex flex-col justify-center items-center mx-auto h-screen">
   <div className="w-full md:max-w-[315px] flex justify-center flex-col flex-1 md:flex-none">
    {data?.getMunicipalityPublicData?.[0]?.logo && (
     <img
      className="self-center w-full h-auto mb-[110px]"
      src={data?.getMunicipalityPublicData?.[0]?.logo}
      alt="Municipality-logo"
     />
    )}
    <div
     onClick={() => handleNavigation('search-citation')}
     className="cursor-pointer bg-white flex flex-col items-center w-full py-9 rounded border mb-2 border-[rgba(0,0,0,0.1)] hover:border-black"
    >
     <Receipt height="30" width="30" />
     <span className="text-lg font-medium mt-4">Pay a Citation</span>
    </div>
    <div
     onClick={() => handleNavigation('permit-email')}
     className="cursor-pointer bg-white flex flex-col items-center w-full py-9 rounded border border-[rgba(0,0,0,0.1)] hover:border-black"
    >
     <Car width="30" height="30" />
     <span className="text-lg font-medium mt-4">Get a Permit</span>
    </div>
   </div>
   <div className="fixed top-0 left-0 cursor-pointer w-screen md:w-fit bg-[#FAFAFA] py-[37px] px-[30px]">
    <div
     className="flex justify-start items-center gap-[10px]"
     onClick={() => navigate(`/`)}
    >
     <Back />
     <p className="font-bold">Back</p>
    </div>
   </div>
  </div>
 );
};

export default OptionsPage;
