import React from 'react';
import { Outlet } from 'react-router-dom';
import Footer from './Footer';
import { Toaster } from 'react-hot-toast';

const MainLayout = () => {
 return (
  <>
   <Toaster position="top-right" />
   <div className="px-[26px] bg-[#FAFAFA] pt-16 md:pt-0">
    <Outlet />
   </div>
   <Footer />
  </>
 );
};

export default MainLayout;
