import { useLazyQuery, useMutation, useQuery } from '@apollo/client';
import React, { useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import {
 GET_MUNICIPALITY_PUBLIC_DATA,
 GET_PERMITS_BY_EMAIL,
 SEND_OTP,
} from '../apis';
import AppInput from '../components/AppInput';
import Button from '../components/Button';
import { Back } from '../assets';
import { isValidEmail } from '../util/helperFunctions';

const PermitEmailPage = () => {
 const navigate = useNavigate();
 const { municipalityName } = useParams();
 const { data } = useQuery(GET_MUNICIPALITY_PUBLIC_DATA, {
  variables: { municipalityName: municipalityName },
  skip: !municipalityName,
 });
 const [getPermitsByOwnerEmail] = useLazyQuery(GET_PERMITS_BY_EMAIL, {
  fetchPolicy: 'network-only',
 });
 const [sendOtp] = useMutation(SEND_OTP);
 const [inputValue, setInputValue] = useState('');
 const [inputError, setInputError] = useState('');
 const handleNext = async () => {
  if (!inputValue) {
   setInputError('Please enter an email.');
   return;
  }
  if (!isValidEmail(inputValue)) {
   setInputError('Please enter a valid email.');
   return;
  }
  try {
   const response = await getPermitsByOwnerEmail({
    variables: {
     ownerEmail: inputValue,
     municipalityId: data?.getMunicipalityPublicData?.[0]?.id,
    },
   });
   if (response?.data?.getPermitsByOwnerEmail?.length) {
    await sendOtp({
     variables: {
      email: inputValue,
      municipalityId: data?.getMunicipalityPublicData?.[0]?.id,
     },
    });
    navigate(`/${municipalityName}/email-validation?email=${inputValue}`);
   } else {
    setInputError('Permit with this email doesn’t exist');
   }
  } catch (error) {
   console.log(error);
  }
 };

 return (
  <div className="flex flex-col justify-center items-center mx-auto h-screen">
   <div className="w-full md:max-w-[315px] flex justify-center flex-col flex-1 md:flex-none">
    {data?.getMunicipalityPublicData?.[0]?.logo && (
     <img
      className="self-center w-full h-auto mb-[64px]"
      src={data?.getMunicipalityPublicData?.[0]?.logo}
      alt="Municipality-logo"
     />
    )}
    <div>
     <AppInput
      value={inputValue}
      type="text"
      placeholder="Your Email"
      onChange={(e) => {
       setInputValue(e?.target?.value);
      }}
     />
     <div className="h-5 mt-1">
      {inputError && (
       <p className="text-red-500 text-xs ">{inputError || ''}</p>
      )}
     </div>
     <Button
      customClass="w-full mt-2"
      name={'Next'}
      size="medium"
      variant="primary"
      onClick={handleNext}
     />
    </div>
   </div>
   <div className="fixed top-0 left-0 cursor-pointer w-screen md:w-fit bg-[#FAFAFA] py-[37px] px-[30px]">
    <div
     className="flex justify-start items-center gap-[10px]"
     onClick={() => navigate(`/${municipalityName}/options`)}
    >
     <Back />
     <p className="font-bold">Back</p>
    </div>
   </div>
  </div>
 );
};

export default PermitEmailPage;
