import React from 'react';

const LogoWithAddress = ({ municipality, ownerName, ownerAddress, showDetail = true }) => {
  return (
    <div className="flex flex-col md:flex-row justify-center gap-9 py-4 w-full">
      {municipality?.logo && (
        <img
          className="align-center w-[156px] h-[156px] object-cover self-center"
          src={municipality?.logo}
          alt="Municipality-logo"
        />
      )}
      {showDetail && (
        <div className="flex flex-col gap-[18px] max-w-[230px] w-full ">
          <div className="flex flex-col justify-start  gap-3">
            <p className="font-semibold">Owner:</p>
            <p className="text-black text-opacity-70">{ownerName || '---'}</p>
          </div>
          <div className="flex flex-col justify-start  gap-3">
            <p className="font-semibold">Owner’s Address:</p>
            <p className="text-black text-opacity-70">{ownerAddress || '---'}</p>
          </div>
        </div>
      )}
    </div>
  );
};

export default LogoWithAddress;
