import React, { useState } from 'react';
import { Back, Receipt, Search } from '../assets';
import Button from '../components/Button';
import OrWithLines from '../components/OrWithLines';
import { STATES } from '../constants';
import { useNavigate, useParams } from 'react-router-dom';
import { useLazyQuery, useQuery } from '@apollo/client'; // Import useLazyQuery
import { GET_CITATION_PERMITS } from './TicketDetails';
import { Toasts } from '../components/Toasts';
import { GET_MUNICIPALITY_PUBLIC_DATA } from '../apis';
import AppInput from '../components/AppInput';

const SearchTicket = () => {
 const navigate = useNavigate();
 const { municipalityName } = useParams();
 const [citationNumber, setCitationNumber] = useState('');
 const [licensePlate, setLicensePlate] = useState('');
 const [state, setState] = useState('');
 const [citaionNumberError, setCitaionNumberError] = useState('');
 const [licensePlateError, setLicensePlateError] = useState('');
 const { data: municipalityData } = useQuery(GET_MUNICIPALITY_PUBLIC_DATA, {
  variables: { municipalityName: municipalityName },
  skip: !municipalityName,
 });
 const [getCitationPermits] = useLazyQuery(GET_CITATION_PERMITS, {
  fetchPolicy: 'network-only',
 });
 const dataExist = async () => {
  try {
   const { data } = await getCitationPermits({
    variables: {
     citationNumber: Number(citationNumber) || null,
     licensePlateState: state || null,
     licensePlateNumber: licensePlate || null,
    },
   });
   return data ? data?.getCitationPermits?.citations?.length !== 0 : false;
  } catch (error) {
   console.log('>>>', error);
   return false;
  }
 };
 // Handle input change for citation number
 const handleCitationChange = (e) => {
  const { value } = e.target;
  setCitationNumber(value);

  // Clear error on change if the input is valid
  if (citaionNumberError && (!value || !/^\d*$/.test(value))) {
   setCitaionNumberError('');
  }
 };

 // Handle input change for license plate
 const handleLicensePlateChange = (e) => {
  const { value } = e.target;
  setLicensePlate(value);
 };

 // Handle state change
 const handleStateChange = (e) => {
  const { value } = e.target;
  setState(value);
 };
 const handleCitationNumberSearch = async () => {
  const datafound = await dataExist();
  if (!datafound) {
   Toasts({
    variant: 'error',
    message: 'No record found for this citation number.',
   });
   return;
  }
  if (!citaionNumberError && citationNumber) {
   setLicensePlateError('');
   setCitaionNumberError('');
   navigate(`/${municipalityName}/ticket?citation-number=${citationNumber}`);
  } else if (!citaionNumberError) {
   setCitaionNumberError('Please enter a valid citation number');
  }
 };

 const handleLicensePlateSearch = async () => {
  const datafound = await dataExist();
  if (!datafound) {
   Toasts({
    variant: 'error',
    message: 'No record found for this license plate.',
   });
   return;
  }
  if (licensePlate && state) {
   setLicensePlateError('');
   setCitaionNumberError('');
   navigate(
    `${municipalityName}/ticket?state=${state}&license-plate=${licensePlate}`,
   );
  } else if (state && !licensePlate) {
   setLicensePlateError('Please select a state');
  } else if (!state && licensePlate) {
   setLicensePlateError('Please enter a valid license plate');
  } else {
   setLicensePlateError('Please enter a valid license plate and state');
  }
 };

 // Validate input on blur for citation number
 const handleCitationBlur = () => {
  if (!/^\d*$/.test(citationNumber)) {
   setCitaionNumberError('Please enter a valid number');
  } else {
   setCitaionNumberError('');
  }
 };

 return (
  <div className="flex flex-col justify-center items-center mx-auto h-screen">
   <div className="w-full md:max-w-[426px] flex justify-center flex-col">
    {municipalityData?.getMunicipalityPublicData?.[0]?.logo && (
     <img
      className="self-center w-[315px] h-auto mb-[64px]"
      src={municipalityData?.getMunicipalityPublicData?.[0]?.logo}
      alt="Municipality-logo"
     />
    )}
    <div className="flex justify-between items-center gap-6 pb-[48px]">
     <Receipt />
     <p className="text-[24px] md:text-[34px] font-semibold tracking-[3.45px] md:tracking-[5px]">
      Ticket Online Pay
     </p>
    </div>

    {/* Search by Citation Number */}
    <div className="flex flex-col md:flex-row items-start ">
     <div className="flex-grow w-full pb-2 md:pb-0 ">
      <AppInput
       type="text"
       name="citationNumber"
       value={citationNumber}
       onChange={handleCitationChange}
       onBlur={handleCitationBlur}
       placeholder="Citation #"
      />
     </div>
     <Button
      isDisabled={!citationNumber}
      customClass="w-full md:w-auto md:ml-2"
      name={'Search'}
      onClick={handleCitationNumberSearch}
      size="medium"
      variant="primary"
      svg={<Search />}
      svgBefore={true}
     />
    </div>
    <div className="h-5 mt-1">
     {citaionNumberError && (
      <p className="text-red-500 text-xs ">{citaionNumberError}</p>
     )}
    </div>

    <OrWithLines />

    {/* Search by License Plate */}
    <div className="flex flex-col md:flex-row justify-between items-center gap-2">
     <div className="flex flex-grow gap-2">
      <AppInput
       type="text"
       name="licensePlate"
       value={licensePlate}
       onChange={handleLicensePlateChange}
       placeholder="License Plate"
      />
      <div className="relative w-[95px]">
       <select
        name="state"
        value={state}
        onChange={handleStateChange}
        className={`font-normal bg-white placeholder:font-normal placeholder:text-sm border ${
         licensePlateError ? 'border-red-500' : ' border-neutral03'
        } hover:border-black hover:border-opacity-30 focus:border-black focus:border-opacity-30 caret-primary05 outline-none rounded-md py-2 pr-10 w-full`}
       >
        <option value="" disable hidden>
         State
        </option>
        {STATES.map(({ value, label }) => (
         <option key={value} value={value}>
          {label}
         </option>
        ))}
       </select>
      </div>
     </div>

     <Button
      isDisabled={!licensePlate || !state}
      customClass="group w-full md:w-auto"
      name="Search"
      onClick={handleLicensePlateSearch}
      size="medium"
      variant="primary"
      svg={<Search />}
      svgBefore={true}
     />
    </div>
    <div className="h-5 mt-1">
     {licensePlateError && (
      <p className="text-red-500 text-xs ">{licensePlateError}</p>
     )}
    </div>
   </div>
   <div className="fixed top-0 left-0 cursor-pointer w-screen md:w-fit bg-[#FAFAFA] py-[37px] px-[30px]">
    <div
     className="flex justify-start items-center gap-[10px]"
     onClick={() => navigate(`/${municipalityName}/options`)}
    >
     <Back />
     <p className="font-bold">Back</p>
    </div>
   </div>
  </div>
 );
};

export default SearchTicket;
