const Search = () => (
 <svg
  className="hovered-icon"
  width="19"
  height="19"
  viewBox="0 0 19 19"
  xmlns="http://www.w3.org/2000/svg"
  fill="white"
 >
  <path d="M15.5672 16.35L10.8422 11.625C10.4672 11.925 10.0359 12.1625 9.54844 12.3375C9.06094 12.5125 8.54219 12.6 7.99219 12.6C6.62969 12.6 5.47656 12.1281 4.53281 11.1844C3.58906 10.2406 3.11719 9.08748 3.11719 7.72498C3.11719 6.36248 3.58906 5.20935 4.53281 4.2656C5.47656 3.32185 6.62969 2.84998 7.99219 2.84998C9.35469 2.84998 10.5078 3.32185 11.4516 4.2656C12.3953 5.20935 12.8672 6.36248 12.8672 7.72498C12.8672 8.27498 12.7797 8.79373 12.6047 9.28123C12.4297 9.76873 12.1922 10.2 11.8922 10.575L16.6172 15.3L15.5672 16.35ZM7.99219 11.1C8.92969 11.1 9.72656 10.7719 10.3828 10.1156C11.0391 9.45935 11.3672 8.66248 11.3672 7.72498C11.3672 6.78748 11.0391 5.9906 10.3828 5.33435C9.72656 4.6781 8.92969 4.34998 7.99219 4.34998C7.05469 4.34998 6.25781 4.6781 5.60156 5.33435C4.94531 5.9906 4.61719 6.78748 4.61719 7.72498C4.61719 8.66248 4.94531 9.45935 5.60156 10.1156C6.25781 10.7719 7.05469 11.1 7.99219 11.1Z" />
 </svg>
);
export default Search;
