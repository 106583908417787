const CardOff = () => (
 <svg
  className="car-icon"
  fill="currentColor"
  width="15"
  height="15"
  viewBox="0 0 15 15"
  xmlns="http://www.w3.org/2000/svg"
 >
  <g clip-path="url(#clip0_2433_6971)">
   <path
    d="M13.5876 11.8887L12.5521 10.8533V7.84915H9.54798L7.21465 5.51582H12.5521V4.34915H6.04798L4.88132 3.18249H12.5521C12.873 3.18249 13.1476 3.29672 13.3761 3.5252C13.6046 3.75367 13.7188 4.02832 13.7188 4.34915V11.3492C13.7188 11.4464 13.7091 11.5412 13.6896 11.6335C13.6702 11.7259 13.6362 11.811 13.5876 11.8887ZM6.22298 7.84915H3.21882V11.3492H9.72298L6.22298 7.84915ZM12.8146 14.4408L10.8896 12.5158H3.21882C2.89798 12.5158 2.62333 12.4016 2.39486 12.1731C2.16638 11.9446 2.05215 11.67 2.05215 11.3492V4.34915C2.05215 4.02832 2.16638 3.75367 2.39486 3.5252C2.62333 3.29672 2.89798 3.18249 3.21882 3.18249L4.38548 4.34915H3.21882V5.51582H3.88965L1.26465 2.89082L2.0959 2.05957L13.6459 13.6096L12.8146 14.4408Z"
    fill="currentColor"
   />
  </g>
  <defs>
   <clipPath id="clip0_2433_6971">
    <rect
     width="14"
     height="14"
     fill="currentColor"
     transform="translate(0.884766 0.848633)"
    />
   </clipPath>
  </defs>
 </svg>
);

export default CardOff;
