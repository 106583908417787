import { useMutation, useQuery } from '@apollo/client';
import React, { useState } from 'react';
import { useLocation, useNavigate, useParams } from 'react-router-dom';

import { CREATE_PAYMENT_SESSION, CREATE_SUBSCRIPTION_SESSION, GET_PERMIT_BY_ID } from '../apis';
import { ClipLoader } from 'react-spinners';
import DetailsComponent from '../components/DetailsComponent';
import Button from '../components/Button';
import { Back, Card } from '../assets';
import { APP_URL, TERM_AND_CONDITIONS_URL } from '../util/constants';

const PayPermit = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const { municipalityName } = useParams();
  const queryParams = new URLSearchParams(location.search);
  const id = queryParams.get('id');
  const email = queryParams.get('email');
  const [creatingSession, setCreatingSession] = useState(false);
  const [privacyCheck, setPrivacyCheck] = useState(false);
  const [subscriptionCheck, setSubscriptionCheck] = useState(false);

  const { data, loading } = useQuery(GET_PERMIT_BY_ID, {
    variables: {
      id,
    },
  });
  const permit = data?.getPermitById;
  const [createPaymentSession] = useMutation(CREATE_PAYMENT_SESSION, {
    fetchPolicy: 'network-only',
  });
  const [createSubscriptionSession] = useMutation(CREATE_SUBSCRIPTION_SESSION, {
    fetchPolicy: 'network-only',
  });

  const getPaymentIntent = async (permitId) => {
    setCreatingSession(true);
    try {
      const { data } = await createPaymentSession({
        variables: {
          permitId,
          cancelUrl: `${APP_URL}pay-permit?id=${permit?.id}&email=${email}`,
          successUrl: `${APP_URL}permits?municipality=${permit?.municipality?.name}&email=${email}`,
        },
      });
      const checkoutUrl = data?.createCheckoutSession;
      if (checkoutUrl) {
        window.location.href = checkoutUrl;
      } else {
        console.error('No checkout URL received from the backend.');
      }
    } catch (error) {
      console.log(error);
    } finally {
      setCreatingSession(false);
    }
  };
  const handleSubscription = async (permitId) => {
    console.log('<><>subscription');
    setCreatingSession(true);
    try {
      const { data } = await createSubscriptionSession({
        variables: {
          permitId,
          cancelUrl: `${APP_URL}/${municipalityName}/pay-permit?id=${permit?.id}&email=${email}`,
          successUrl: `${APP_URL}/${municipalityName}/permits?email=${email}`,
        },
      });
      const checkoutUrl = data?.createSubscriptionSession?.url;
      if (checkoutUrl) {
        window.location.href = checkoutUrl;
      } else {
        console.error('No checkout URL received from the backend.');
      }
    } catch (error) {
      console.log(error);
    } finally {
      setCreatingSession(false);
    }
  };

  return loading ? (
    <div className="flex flex- h-screen justify-center items-center">
      <ClipLoader />
    </div>
  ) : (
    <div className="flex flex-1 md:h-screen justify-center items-center overflow-auto">
      <div className="py-[30px]">
        <div className="flex justify-center items-center">
          {permit?.municipality?.logo && (
            <img
              className="align-center w-[156px] h-[156px] object-cover"
              src={permit?.municipality?.logo}
              alt="Municipality-logo"
            />
          )}
        </div>
        <div className="flex justify-center items-center w-full">
          <div className=" flex-1 flex-col justify-start max-w-[373px] md:max-w-[429px] ">
            <div className="flex flex-col justify-start items-start gap-[18px]">
              <div className="flex flex-col justify-start items-start gap-3">
                <p className="text-xs font-semibold">Owner:</p>
                <p className="text-black text-opacity-70">{permit?.ownerName || '---'}</p>
              </div>
              <div className="flex flex-col justify-start items-start gap-3">
                <p className="text-xs font-semibold">Owner’s Address:</p>
                <p className="text-black text-opacity-70">
                  {`${permit?.ownerAddress}, ${permit?.ownerCity}, ${permit?.ownerState}, ${permit?.ownerZip}`}
                </p>
              </div>
              <div className="border-b w-full"></div>
              <div className="flex flex-col justify-start items-start gap-3">
                <p className="text-xs font-semibold">Vehicle:</p>
                <p className="text-black text-opacity-70">
                  {`${permit?.color || ''} ${permit?.make || ''} ${permit?.modal || ''}`}
                </p>
              </div>
              <DetailsComponent
                titleLeft="License Plate: "
                descriptionLeft={permit?.licensePlateNumber}
                titleRight="State:"
                descriptionRight={permit?.licensePlateState}
              />
              <DetailsComponent titleLeft="Expiring on:" descriptionLeft={permit?.expirationDate} />
              <div className="flex flex-col justify-start items-start gap-3 md:min-w-[430px]">
                <p className="text-xs font-semibold">Address:</p>
                <p className="text-black text-opacity-70">
                  {`${permit?.ownerAddress}, ${permit?.ownerCity}, ${permit?.ownerState}, ${permit?.ownerZip}`}
                </p>
              </div>
              <div className="border-b w-full"></div>
              <div className="flex flex-col justify-start items-start gap-2 rounded-md bg-black bg-opacity-5 w-full p-4">
                <div className="flex justify-between items-start w-full">
                  <p className="text-black text-opacity-70 text-xs">Permit Payment</p>
                  <p className="text-black text-opacity-70 text-xs">${permit?.fee}</p>
                </div>
                <div className="flex justify-between items-start w-full">
                  <p className="text-black text-opacity-70 text-xs">Service Fee</p>
                  <p className="text-black text-opacity-70 text-xs">$0.00</p>
                </div>
                <div className="flex justify-between items-start w-full">
                  <p className="text-black text-opacity-70 font-bold text-xs">Total</p>
                  <p className="text-black text-opacity-70 font-bold text-xs">${permit?.fee}</p>
                </div>
              </div>
            </div>

            <div className="my-[18px]">
              <label className="text-black text-xs flex items-center cursor-pointer">
                <input
                  name="renewable"
                  type="checkbox"
                  onChange={(e) => setPrivacyCheck(e.target.checked)}
                  checked={privacyCheck}
                  style={{
                    accentColor: 'black',
                  }}
                  className="mr-2 w-4 h-4 focus:outline-none focus:ring-0 focus:ring-offset-0 rounded-sm"
                />
                I agree to the{'  '}
                <span
                  onClick={() => window.open(TERM_AND_CONDITIONS_URL, '_blank')}
                  className="ml-1 underline underline-offset-1"
                >
                  Terms and Conditions
                </span>
              </label>
              {permit?.renewable && (
                <label className="text-black text-xs flex items-center cursor-pointer mt-[18px] ">
                  <input
                    name="renewable"
                    type="checkbox"
                    onChange={(e) => setSubscriptionCheck(e.target.checked)}
                    checked={subscriptionCheck}
                    style={{
                      accentColor: 'black',
                    }}
                    className="mr-2 w-4 h-4 focus:outline-none focus:ring-0 focus:ring-offset-0 rounded-sm"
                  />
                  I agree to be charged the amount above on a recurring basis for my parking
                  privileges
                </label>
              )}
            </div>

            <div className="flex flex-row flex-wrap-reverse justify-end mt-4 ">
              <Button
                isDiaa={creatingSession}
                name={'cancel'}
                customClass=" !tracking-[2px] w-full md:w-auto mt-2 md:mt-0"
                onClick={() => navigate(-1)}
                size="small"
                variant="secondary"
              />
              <Button
                isDisabled={permit?.renewable ? !privacyCheck || !subscriptionCheck : !privacyCheck}
                loading={creatingSession}
                name={'Checkout'}
                customClass={`!tracking-[2px] md:mr-2 w-full md:w-auto ml-2`}
                onClick={() =>
                  permit?.renewable ? handleSubscription(permit?.id) : getPaymentIntent(permit?.id)
                }
                size="small"
                variant="primary"
                svg={<Card />}
                svgBefore={true}
              />
            </div>
          </div>
        </div>
        <div className="fixed top-0 left-0 cursor-pointer w-screen md:w-fit bg-[#FAFAFA] py-[37px] px-[30px]">
          <div className="flex justify-start items-center gap-[10px]" onClick={() => navigate(-1)}>
            <Back />
            <p className="font-bold">Back</p>
          </div>
        </div>
      </div>
    </div>
  );
};

export default PayPermit;
