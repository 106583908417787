const Attach = () => (
 <svg
  fill="currentColor"
  width="15"
  height="15"
  viewBox="0 0 15 15"
  xmlns="http://www.w3.org/2000/svg"
 >
  <path
   d="M11.4245 9.23177C11.4245 10.2429 11.0696 11.1033 10.3599 11.813C9.65017 12.5227 8.78976 12.8776 7.77865 12.8776C6.76753 12.8776 5.90712 12.5227 5.1974 11.813C4.48767 11.1033 4.13281 10.2429 4.13281 9.23177V3.83594C4.13281 3.10677 4.38802 2.48698 4.89844 1.97656C5.40885 1.46615 6.02865 1.21094 6.75781 1.21094C7.48698 1.21094 8.10677 1.46615 8.61719 1.97656C9.1276 2.48698 9.38281 3.10677 9.38281 3.83594V8.9401C9.38281 9.38733 9.22726 9.76649 8.91615 10.0776C8.60503 10.3887 8.22587 10.5443 7.77865 10.5443C7.33142 10.5443 6.95226 10.3887 6.64115 10.0776C6.33003 9.76649 6.17448 9.38733 6.17448 8.9401V3.54427H7.34115V8.9401C7.34115 9.06649 7.38247 9.17101 7.4651 9.25364C7.54774 9.33628 7.65226 9.3776 7.77865 9.3776C7.90503 9.3776 8.00955 9.33628 8.09219 9.25364C8.17483 9.17101 8.21615 9.06649 8.21615 8.9401V3.83594C8.20642 3.4276 8.06302 3.08247 7.78594 2.80052C7.50885 2.51858 7.16615 2.3776 6.75781 2.3776C6.34948 2.3776 6.00434 2.51858 5.7224 2.80052C5.44045 3.08247 5.29948 3.4276 5.29948 3.83594V9.23177C5.28976 9.92205 5.52795 10.5078 6.01406 10.9891C6.50017 11.4703 7.08837 11.7109 7.77865 11.7109C8.4592 11.7109 9.03767 11.4703 9.51406 10.9891C9.99045 10.5078 10.2384 9.92205 10.2578 9.23177V3.54427H11.4245V9.23177Z"
   fill="currentColor"
  />
 </svg>
);

export default Attach;
