const Card = () => (
 <svg
  className="car-icon"
  width="15"
  height="15"
  viewBox="0 0 15 15"
  fill="currentColor" // This allows CSS to control the color
  xmlns="http://www.w3.org/2000/svg"
 >
  <path d="M13.0026 3.54362V10.5436C13.0026 10.8645 12.8884 11.1391 12.6599 11.3676C12.4314 11.5961 12.1568 11.7103 11.8359 11.7103H2.5026C2.18177 11.7103 1.90712 11.5961 1.67865 11.3676C1.45017 11.1391 1.33594 10.8645 1.33594 10.5436V3.54362C1.33594 3.22279 1.45017 2.94813 1.67865 2.71966C1.90712 2.49119 2.18177 2.37695 2.5026 2.37695H11.8359C12.1568 2.37695 12.4314 2.49119 12.6599 2.71966C12.8884 2.94813 13.0026 3.22279 13.0026 3.54362ZM2.5026 4.71029H11.8359V3.54362H2.5026V4.71029ZM2.5026 7.04362V10.5436H11.8359V7.04362H2.5026Z" />
 </svg>
);

export default Card;
