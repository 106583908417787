export const APP_URL = 'https://ticketonlinepay.com/';
// export const APP_URL = 'http://localhost:3001/';

export const PERMIT_STATUS_COLORS = {
  IN_PRODUCTION: '#008CD2',
  ACTIVE: '#47B403',
  EXPIRED: '#DD5353',
  PENDING_PAYMENT: '#FACE4F',
  LOST: '#666666',
  STOLEN: '#666666',
  RETURNED: '#666666',
  TEMPORARY: '#8E70C0',
};

export const CITATION_STATUS_COLORS = {
  PAYMENT_DUE: '#FACE4F',
  PAID: '#47B403',
  APPEAL_PENDING: '#008CD2',
  NSF_CHECK_HOLD: '#DD5353',
  DMV_HOLD: '#DD5353',
  HEARING_SCHEDULED: '#8E70C0',
  COLLECTIONS: '#DD5353',
  UNCOLLECTIBLE: '#666666',
  APPEAL_UPHELD: '#FACE4F',
};

export const SHOW_CONTEST_STATUSES = ['PAYMENT_DUE'];
export const PERMIT_PAYMENT_PENDING = ['PENDING_PAYMENT'];
export const CITATION_PAYMENT_PENDING = ['APPEAL_PENDING', 'PAYMENT_DUE', 'APPEAL_UPHELD'];

export const PRIVACY_POLICY_URL =
  'https://enforce-uploads.s3.us-east-1.amazonaws.com/Ticket+Online+Pay+Privacy+Policy+.pdf';
export const TERM_AND_CONDITIONS_URL =
  'https://enforce-uploads.s3.us-east-1.amazonaws.com/Ticket+Online+Pay+T%26C.pdf';
