import React from 'react';

const Receipt = ({ height = '46', width = '46' }) => {
 return (
  <svg
   width={width}
   height={height}
   viewBox="0 0 46 46"
   fill="none"
   xmlns="http://www.w3.org/2000/svg"
  >
   <path
    d="M11.7685 41.8486C10.216 41.8486 8.89647 41.3052 7.80976 40.2185C6.72304 39.1318 6.17969 37.8122 6.17969 36.2597V30.6709H11.7685V4.58984L14.5629 7.38425L17.3573 4.58984L20.1517 7.38425L22.9461 4.58984L25.7405 7.38425L28.5349 4.58984L31.3293 7.38425L34.1237 4.58984L36.9181 7.38425L39.7125 4.58984V36.2597C39.7125 37.8122 39.1692 39.1318 38.0825 40.2185C36.9957 41.3052 35.6762 41.8486 34.1237 41.8486H11.7685ZM34.1237 38.1227C34.6516 38.1227 35.094 37.9441 35.4511 37.5871C35.8081 37.23 35.9867 36.7876 35.9867 36.2597V10.1786H15.4944V30.6709H32.2608V36.2597C32.2608 36.7876 32.4393 37.23 32.7964 37.5871C33.1534 37.9441 33.5959 38.1227 34.1237 38.1227ZM17.3573 17.6304V13.9045H28.5349V17.6304H17.3573ZM17.3573 23.2192V19.4933H28.5349V23.2192H17.3573ZM32.2608 17.6304C31.733 17.6304 31.2905 17.4519 30.9334 17.0948C30.5764 16.7377 30.3978 16.2953 30.3978 15.7675C30.3978 15.2396 30.5764 14.7972 30.9334 14.4401C31.2905 14.0831 31.733 13.9045 32.2608 13.9045C32.7886 13.9045 33.2311 14.0831 33.5881 14.4401C33.9452 14.7972 34.1237 15.2396 34.1237 15.7675C34.1237 16.2953 33.9452 16.7377 33.5881 17.0948C33.2311 17.4519 32.7886 17.6304 32.2608 17.6304ZM32.2608 23.2192C31.733 23.2192 31.2905 23.0407 30.9334 22.6836C30.5764 22.3265 30.3978 21.8841 30.3978 21.3563C30.3978 20.8284 30.5764 20.386 30.9334 20.0289C31.2905 19.6719 31.733 19.4933 32.2608 19.4933C32.7886 19.4933 33.2311 19.6719 33.5881 20.0289C33.9452 20.386 34.1237 20.8284 34.1237 21.3563C34.1237 21.8841 33.9452 22.3265 33.5881 22.6836C33.2311 23.0407 32.7886 23.2192 32.2608 23.2192ZM11.7685 38.1227H28.5349V34.3968H9.90556V36.2597C9.90556 36.7876 10.0841 37.23 10.4412 37.5871C10.7982 37.9441 11.2407 38.1227 11.7685 38.1227Z"
    fill="black"
   />
  </svg>
 );
};

export default Receipt;
