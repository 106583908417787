import { useQuery } from '@apollo/client';
import React from 'react';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import {
 GET_MUNICIPALITY_PUBLIC_DATA,
 GET_PERMITS_BY_EMAIL_WITH_DETAIL,
} from '../apis';
import LogoWithAddress from '../components/LogoWithAddress';
import { ClipLoader } from 'react-spinners';
import { Back } from '../assets';
import VehicleDetailCard from '../components/VehicleDetailCard';

const PermitsListingPage = () => {
 const location = useLocation();
 const navigate = useNavigate();
 const { municipalityName } = useParams();
 const queryParams = new URLSearchParams(location.search);
 const email = queryParams.get('email');
 const { data: municipalityData, loading: loadingMunicipality } = useQuery(
  GET_MUNICIPALITY_PUBLIC_DATA,
  {
   variables: { municipalityName: municipalityName },
   skip: !municipalityName,
  },
 );
 const { data, loading, refetch } = useQuery(GET_PERMITS_BY_EMAIL_WITH_DETAIL, {
  variables: {
   ownerEmail: email,
   municipalityId: municipalityData?.getMunicipalityPublicData?.[0]?.id,
  },
 });

 const firstPermit = data?.getPermitsByOwnerEmail?.[0];
 const ownerAddress = `${firstPermit?.ownerAddress}, ${firstPermit?.ownerCity}, ${firstPermit?.ownerState}, ${firstPermit?.ownerZip}`;
 return loading || loadingMunicipality ? (
  <div className="flex flex- h-screen justify-center items-center">
   <ClipLoader />
  </div>
 ) : data?.getPermitsByOwnerEmail?.length > 0 ? (
  <>
   <div className="flex flex-col justify-start items-center md:h-[calc(100vh-121px)] overflow-auto">
    <LogoWithAddress
     municipality={municipalityData?.getMunicipalityPublicData?.[0]}
     ownerAddress={ownerAddress}
     ownerName={data?.getPermitsByOwnerEmail?.[0]?.ownerName}
    />
    <div className="md:w-[704px] pb-11">
     <div className="border-b my-8"></div>
     <div className="flex flex-col items-stretch md:flex-row md:justify-start md:items-stretch md:flex-wrap justify-between gap-4 pb-[32px]">
      {data?.getPermitsByOwnerEmail.map((data) => {
       return (
        <VehicleDetailCard permit={data} email={email} refetch={refetch} />
       );
      })}
     </div>
    </div>
   </div>
   <div className="fixed top-0 left-0 cursor-pointer w-screen md:w-fit bg-[#FAFAFA] py-[37px] px-[30px]">
    <div
     className="flex justify-start items-center gap-[10px]"
     onClick={() => navigate(`/${municipalityName}/permit-email`)}
    >
     <Back />
     <p className="font-bold">Back</p>
    </div>
   </div>
  </>
 ) : (
  <div className="h-[calc(100vh-121px)] flex flex-1 justify-center items-center">
   <div className="fixed top-0 left-0 cursor-pointer w-screen md:w-fit bg-[#FAFAFA] py-[37px] px-[30px]">
    <div
     className="flex justify-start items-center gap-[10px]"
     onClick={() => navigate(`/${municipalityName}/permit-email`)}
    >
     <Back />
     <p className="font-bold">Back</p>
    </div>
   </div>
   No Data found
  </div>
 );
};

export default PermitsListingPage;
