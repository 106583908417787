import React, { useCallback, useEffect, useState } from 'react';
import { Back, Card, Image } from '../assets';
import DetailsComponent from '../components/DetailsComponent';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import { encodeMunicipalityName, generateAddress } from '../util/helperFunctions';
import { gql, useLazyQuery, useMutation } from '@apollo/client';
import { GET_CITATION_PERMITS } from './TicketDetails';
import { ClipLoader } from 'react-spinners';
import Button from '../components/Button';
import { APP_URL, TERM_AND_CONDITIONS_URL } from '../util/constants';
const CREATE_PAYMENT_SESSION = gql`
  mutation CreateCheckoutSession(
    $citationId: String
    $permitId: String
    $successUrl: String
    $cancelUrl: String
  ) {
    createCheckoutSession(
      createCheckoutSessionInput: {
        citationId: $citationId
        permitId: $permitId
        successUrl: $successUrl
        cancelUrl: $cancelUrl
      }
    )
  }
`;
const PayCitation = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const { municipalityName } = useParams();
  const queryParams = new URLSearchParams(location.search);
  const citationNumber = queryParams.get('citation-number');
  const [loading, setLoading] = useState(false);
  const [creatingSession, setCreatingSession] = useState(false);
  const [privacyCheck, setPrivacyCheck] = useState(false);
  const [data, setData] = useState();
  const [municipality, setMunicipality] = useState();
  const [getCitationPermits] = useLazyQuery(GET_CITATION_PERMITS, {
    fetchPolicy: 'network-only',
  });
  const [createPaymentSession] = useMutation(CREATE_PAYMENT_SESSION, {
    fetchPolicy: 'network-only',
  });
  const goBack = () => {
    navigate(`/${municipalityName}/ticket?citation-number=${citationNumber}`);
  };
  const getPaymentIntent = async (citationId) => {
    setCreatingSession(true);
    try {
      const encodedMunicipalityName = encodeMunicipalityName(municipalityName);

      const { data } = await createPaymentSession({
        variables: {
          citationId,
          cancelUrl: `${APP_URL}${encodedMunicipalityName}/pay-citation?citation-number=${citationNumber}`,
          successUrl: `${APP_URL}${encodedMunicipalityName}/ticket?citation-number=${citationNumber}`,
        },
      });

      const checkoutUrl = data?.createCheckoutSession;
      if (checkoutUrl) {
        window.location.href = checkoutUrl;
      } else {
        console.error('No checkout URL received from the backend.');
      }
    } catch (error) {
      console.log(error);
    } finally {
      setCreatingSession(false);
    }
  };
  const handleSearchData = useCallback(async () => {
    try {
      setLoading(true);
      const { data } = await getCitationPermits({
        variables: {
          citationNumber: Number(citationNumber),
        },
      });

      const currentCitations = data?.getCitationPermits.citations?.find(
        (citation) => citation?.citationNumber === Number(citationNumber),
      );
      setData(currentCitations);
      if (data?.getCitationPermits?.citations?.[0]) {
        setMunicipality(data?.getCitationPermits?.citations?.[0]?.municipality);
      } else if (data?.getCitationPermits?.permits?.[0]) {
        setMunicipality(data?.getCitationPermits?.permits?.[0]?.municipality);
      }
    } catch (error) {
      console.log('>>>', error);
    } finally {
      setLoading(false);
    }
  }, [citationNumber, getCitationPermits]);
  useEffect(() => {
    if (citationNumber) {
      handleSearchData();
    }
  }, [citationNumber, handleSearchData]);

  return loading ? (
    <div className="flex flex- h-screen justify-center items-center">
      <ClipLoader />
    </div>
  ) : (
    <>
      <div className="py-[30px]">
        <div className="flex justify-center items-center">
          {municipality?.logo && (
            <img
              className="align-center w-[156px] h-[156px] object-cover"
              src={municipality?.logo}
              alt="Municipality-logo"
            />
          )}
        </div>
        <div className="flex justify-center items-center w-full">
          <div className=" flex-1 flex-col justify-start max-w-[373px] md:max-w-[429px] ">
            <div className="flex flex-col justify-start items-start gap-[18px]">
              <div className="flex flex-col justify-start items-start gap-3">
                <p className="text-xs font-semibold">Owner:</p>
                <p className="text-black text-opacity-70">John Hancock</p>
              </div>
              <div className="flex flex-col justify-start items-start gap-3">
                <p className="text-xs font-semibold">Owner’s Address:</p>
                <p className="text-black text-opacity-70">
                  04914 Murray Lights Apt. 037, Fort Collins, NC, 21085
                </p>
              </div>
              <div className="border-b w-full"></div>
              <div className="flex flex-col justify-start items-start gap-3">
                <p className="text-xs font-semibold">Vehicle:</p>
                <p className="text-black text-opacity-70">
                  {`${data?.color || ''} ${data?.make || ''} ${data?.modal || ''}`}
                </p>
              </div>
              <DetailsComponent
                titleLeft="License Plate: "
                descriptionLeft={data?.licensePlateNumber || '---'}
                titleRight="State:"
                descriptionRight={data?.licensePlateState || '---'}
              />
              <DetailsComponent
                titleLeft="Status:  "
                descriptionLeft="Active"
                titleRight="Reg. Expiring:"
                descriptionRight="09/21/2024"
              />
              <div className="flex flex-col justify-start items-start gap-3">
                <p className="text-xs font-semibold">Registration Address:</p>
                <p className="text-black text-opacity-70">
                  04914 Murray Lights Apt. 037, Fort Collins, NC, 21085
                </p>
              </div>
              <div className="border-b w-full"></div>
              <DetailsComponent
                titleLeft="Date/Time: "
                descriptionLeft={data?.date}
                titleRight="Type of Violation:"
                descriptionRight={data?.violation?.title}
              />
              <DetailsComponent
                titleLeft="Parking in bike lane "
                descriptionLeft="Not Paid"
                titleRight="Amount Owed:"
                descriptionRight={data?.fineAmount}
              />
              <DetailsComponent
                titleLeft="Citation #:"
                descriptionLeft={data?.citationNumber}
                titleRight="Evidence:"
                descriptionRight={
                  data?.photo ? (
                    <a href={data?.photo} target="_blank" rel="noopener noreferrer">
                      <div className="flex justify-start items-center gap-2">
                        <Image />
                        <p className="text-xs text-black text-opacity-70">evidence.png</p>
                      </div>
                    </a>
                  ) : (
                    '---'
                  )
                }
              />

              <div className="flex flex-col justify-start items-start gap-3">
                <p className="text-xs font-semibold">Address of Violation:</p>
                <p className="text-black text-opacity-70">
                  {data?.location?.address || generateAddress(data?.locationResult) || '---'}
                </p>
              </div>

              <div className="border-b w-full"></div>
              <label className="text-black text-xs flex items-center cursor-pointer">
                <input
                  name="renewable"
                  type="checkbox"
                  onChange={(e) => setPrivacyCheck(e.target.checked)}
                  checked={privacyCheck}
                  style={{
                    accentColor: 'black',
                  }}
                  className="mr-2 w-4 h-4 focus:outline-none focus:ring-0 focus:ring-offset-0 rounded-sm"
                />
                I agree to the{'  '}
                <span
                  onClick={() => window.open(TERM_AND_CONDITIONS_URL, '_blank')}
                  className="ml-1 underline underline-offset-1"
                >
                  Terms and Conditions
                </span>
              </label>
              <div className="flex flex-col justify-start items-start gap-2 rounded-md bg-black bg-opacity-5 w-full p-4">
                <div className="flex justify-between items-start w-full">
                  <p className="text-black text-opacity-70 text-xs">Citation Payment</p>
                  <p className="text-black text-opacity-70 text-xs">${data?.fineAmount}</p>
                </div>
                <div className="flex justify-between items-start w-full">
                  <p className="text-black text-opacity-70 text-xs">Service Fee</p>
                  <p className="text-black text-opacity-70 text-xs">$0.00</p>
                </div>
                <div className="flex justify-between items-start w-full">
                  <p className="text-black text-opacity-70 font-bold text-xs">Total</p>
                  <p className="text-black text-opacity-70 font-bold text-xs">
                    ${data?.fineAmount}
                  </p>
                </div>
              </div>
            </div>
            <div className="flex justify-end mt-4 ">
              <Button
                disabled={creatingSession}
                name={'cancel'}
                customClass=" !tracking-[2px] w-full md:w-auto"
                onClick={goBack}
                size="small"
                variant="secondary"
              />
              <Button
                isDisabled={!privacyCheck}
                loading={creatingSession}
                name={'Checkout'}
                customClass=" !tracking-[2px] md:mr-2 w-full md:w-auto ml-2"
                onClick={() => getPaymentIntent(data?.id)}
                size="small"
                variant="primary"
                svg={<Card />}
                svgBefore={true}
              />
            </div>
          </div>
        </div>
        <div className="fixed top-0 left-0 cursor-pointer w-screen md:w-fit bg-[#FAFAFA] py-[37px] px-[30px]">
          <div className="flex justify-start items-center gap-[10px]" onClick={goBack}>
            <Back />
            <p className="font-bold">Back</p>
          </div>
        </div>
      </div>
    </>
  );
};

export default PayCitation;
