import React, { useState } from 'react';
import { useQuery } from '@apollo/client';
import { debounce } from 'lodash';
import { Receipt } from '../assets';
import Button from '../components/Button';
import { useLocation, useNavigate } from 'react-router-dom';
import { GET_MUNICIPALITY_PUBLIC_DATA } from '../apis';
import AppInput from '../components/AppInput';

const HomePage = () => {
 const [searchTerm, setSearchTerm] = useState('');
 const [inputValue, setInputValue] = useState('');
 const [filteredOptions, setFilteredOptions] = useState([]);
 const [selectedMunicipality, setSelectedMunicipality] = useState(null);
 const navigate = useNavigate();
 const location = useLocation();

 // Use Apollo Client's useQuery hook to fetch data
 const { data, loading, error } = useQuery(GET_MUNICIPALITY_PUBLIC_DATA, {
  variables: { municipalityName: searchTerm },
  skip: !searchTerm, // Skip the query if searchTerm is empty
 });

 // Debounce the search input handler
 const handleSearchChange = debounce((term) => {
  setSelectedMunicipality(null);
  setSearchTerm(term);
 }, 300);

 const handleDropdownSelect = (municipality) => {
  setSelectedMunicipality(municipality);
  setSearchTerm('');
  setFilteredOptions([]);
 };

 const handleMunicipalitySelect = () => {
  if (selectedMunicipality) {
   const searchParams = new URLSearchParams(location.search);
   searchParams.set('municipality', selectedMunicipality.name);

   navigate(`${selectedMunicipality.name}`);
  }
 };
 // Update filtered options when data changes
 React.useEffect(() => {
  if (data && data.getMunicipalityPublicData) {
   setFilteredOptions(data.getMunicipalityPublicData);
  } else {
   setFilteredOptions([]);
  }
 }, [data]);

 return (
  <div className="mx-auto md:px-0 max-w-[426px] flex justify-center flex-col flex-1 md:flex-none h-svh">
   <div className="flex flex-row justify-center items-center gap-6 pb-[64px] pt-[74px] md:pt-0">
    <Receipt />
    <p className="text-[24px] md:text-[34px] font-semibold tracking-[3.45px] md:tracking-[5px]">
     Ticket Online Pay
    </p>
   </div>
   <div className="flex flex-col md:flex-row items-start flex-1 md:flex-none justify-between">
    <div className="w-full pb-2 md:pb-0 relative">
     <AppInput
      value={selectedMunicipality ? selectedMunicipality.name : inputValue}
      type="text"
      placeholder="Parking Authority"
      onChange={(e) => {
       setInputValue(e?.target?.value);
       handleSearchChange(e.target.value);
      }}
     />

     {(loading || error) && (
      <ul className="absolute left-0 w-full max-w-[426px]  bg-white mt-1 rounded-md z-10 max-h-96 md:max-h-64 overflow-y-auto shadow-[rgba(0,_0,_0,_0.24)_0px_3px_8px]">
       <li className="cursor-pointer py-2 px-4 hover:bg-[rgba(0,0,0,0.05)]">
        {loading ? <p>Loading...</p> : error ? <p>{error.message}</p> : ''}
       </li>
      </ul>
     )}
     {filteredOptions.length > 0 && (
      <ul className="absolute left-0 w-full max-w-[426px] bg-white mt-1 rounded-md z-10 max-h-96 md:max-h-64 overflow-y-auto shadow-[rgba(0,_0,_0,_0.24)_0px_3px_8px]">
       {filteredOptions.map((municipality, index) => (
        <li
         key={index}
         className="cursor-pointer py-2 px-4 hover:bg-[rgba(0,0,0,0.05)]"
         onClick={() => handleDropdownSelect(municipality)}
        >
         {municipality.name}
        </li>
       ))}
      </ul>
     )}
    </div>
    <Button
     isDisabled={!selectedMunicipality}
     customClass="w-full md:w-auto md:ml-2 mb-[50px] md:mb-0"
     name={'Select'}
     onClick={handleMunicipalitySelect}
     size="medium"
     variant="primary"
    />
   </div>
  </div>
 );
};

export default HomePage;
