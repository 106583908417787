import { useLazyQuery, useMutation, useQuery } from '@apollo/client';
import React, { useCallback, useEffect, useState } from 'react';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import {
 GET_MUNICIPALITY_PUBLIC_DATA,
 GET_PERMITS_BY_EMAIL,
 SEND_OTP,
 VERIFY_OTP,
} from '../apis';
import AppInput from '../components/AppInput';
import Button from '../components/Button';
import { Back } from '../assets';
import { isValidEmail } from '../util/helperFunctions';
import OtpInput from 'react-otp-input';

const EmailValidationPage = () => {
 const navigate = useNavigate();
 const location = useLocation();
 const { municipalityName } = useParams();
 const [otp, setOtp] = useState('');
 const searchParams = new URLSearchParams(location.search);
 const email = searchParams.get('email');
 const { data } = useQuery(GET_MUNICIPALITY_PUBLIC_DATA, {
  variables: { municipalityName: municipalityName },
  skip: !municipalityName,
 });
 const [getPermitsByOwnerEmail] = useLazyQuery(GET_PERMITS_BY_EMAIL, {
  nextFetchPolicy: 'network-only',
 });
 const [sendOtp] = useMutation(SEND_OTP);
 const [verifyOtp] = useMutation(VERIFY_OTP);
 const [inputValue, setInputValue] = useState(email);
 const [inputError, setInputError] = useState('');
 const [otpError, setOtpError] = useState('');
 const [inputSuccessMessage, setInputSuccessMessage] = useState('');
 const [timer, setTimer] = useState(0);
 const [loading, setLoading] = useState(false);
 useEffect(() => {
  setTimer(60);
  setInputSuccessMessage(
   "'Email Sent! Code will expire within 10 minutes. Please enter the code below and press “Next” to continue',",
  );
 }, []);
 const handleResendCode = useCallback(async () => {
  if (timer > 0) return;
  if (!email) return;
  try {
   await sendOtp({
    variables: {
     email,
     municipalityId: data?.getMunicipalityPublicData?.[0]?.id,
    },
   });
   setInputError(null);
   setOtpError(null);
   setTimer(60);
   setInputSuccessMessage(
    'Email Sent! Code will expire within 10 minutes. Please enter the code below and press “Next” to continue',
   );
  } catch (error) {
   console.log('ERROR', error);
  }
 }, [data?.getMunicipalityPublicData, email, sendOtp, timer]);

 const handleVerifyOtp = async () => {
  try {
   const { data } = await verifyOtp({
    variables: {
     email,
     otp,
    },
   });
   return data;
  } catch (error) {
   console.log('Error while verify', error);
  }
 };

 useEffect(() => {
  let interval;
  if (timer > 0) {
   interval = setInterval(() => {
    setTimer((prev) => prev - 1);
   }, 1000);
  }
  return () => clearInterval(interval);
 }, [timer]);

 const handleNext = async () => {
  if (!inputValue) {
   setInputError('Please enter an email.');
   return;
  }
  if (!isValidEmail(inputValue)) {
   setInputError('Please enter a valid email.');
   return;
  }
  setLoading(true);
  try {
   const otpResult = await handleVerifyOtp();
   setInputSuccessMessage(null);

   if (otpResult?.verifyOtp) {
    const response = await getPermitsByOwnerEmail({
     variables: {
      ownerEmail: inputValue,
      municipalityId: data?.getMunicipalityPublicData?.[0]?.id,
     },
    });
    if (response?.data?.getPermitsByOwnerEmail?.length) {
     navigate(`/${municipalityName}/permits?email=${email}`, {
      state: { email: inputValue },
     });
    } else {
     setInputError('Permit with this email doesn’t exist');
    }
   } else {
    setOtpError('Incorrect code');
   }
  } catch (error) {
   console.log(error);
  } finally {
   setLoading(false);
  }
 };
 return (
  <div className="flex flex-col justify-center items-center mx-auto h-screen">
   <div className="w-full md:max-w-[315px] flex justify-center flex-col flex-1 md:flex-none">
    {data?.getMunicipalityPublicData?.[0]?.logo && (
     <img
      className="self-center w-full h-auto mb-[64px]"
      src={data?.getMunicipalityPublicData?.[0]?.logo}
      alt="Municipality-logo"
     />
    )}
    <div className="flex flex-col items-center">
     <AppInput
      value={inputValue}
      type="text"
      placeholder="Your Email"
      onChange={(e) => {
       setInputValue(e?.target?.value);
      }}
     />
     <div className="w-full h-11 mt-1 mb-9 flex flex-row justify-start">
      {inputError && (
       <p className="text-red-500 text-xs ">{inputError || ''}</p>
      )}
      {inputSuccessMessage && (
       <p className="text-[#47B403] text-xs ">
        Email Sent! Code will expire within 10 minutes. Please enter the code
        below and press “Next” to continue
       </p>
      )}
     </div>
     <OtpInput
      inputStyle={{
       height: '36px',
       padding: 0,
       width: '24px',
       borderRadius: '5px',
       borderWidth: 1,
       fontSize: '12px',
      }}
      value={otp}
      onChange={setOtp}
      numInputs={6}
      renderInput={(props, index) => (
       <input
        {...props}
        style={{
         ...props.style,
         borderColor: otp[index] ? 'black' : 'rgba(0,0,0,0.3)',
         marginRight: index === 2 ? '24px' : '8px',
        }}
       />
      )}
     />

     <div className="h-14 mt-9 w-full mb-2 flex flex-col items-center justify-center">
      <div className="h-5 w-full mb-2">
       {otpError && (
        <p className="text-red-500 text-xs self-start">{otpError}</p>
       )}
      </div>
      {timer > 0 ? (
       <p className="text-black text-xs -mb-3">
        Wait <span className="font-bold">{timer}s</span> to re-send code
       </p>
      ) : (
       <Button
        customClass="w-full border border-[#0000001a] bg-transparent"
        name={'Re-Send Code'}
        size="medium"
        variant="secondary"
        onClick={handleResendCode}
       />
      )}
     </div>
     <Button
      loading={loading}
      customClass="w-full mt-2"
      name={'Next'}
      size="medium"
      variant="primary"
      onClick={handleNext}
     />
    </div>
   </div>
   <div className="fixed top-0 left-0 cursor-pointer w-screen md:w-fit bg-[#FAFAFA] py-[37px] px-[30px]">
    <div
     className="flex justify-start items-center gap-[10px]"
     onClick={() => navigate(-1)}
    >
     <Back />
     <p className="font-bold">Back</p>
    </div>
   </div>
  </div>
 );
};

export default EmailValidationPage;
