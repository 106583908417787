import { ApolloClient, InMemoryCache, ApolloLink } from '@apollo/client';
import { setContext } from '@apollo/client/link/context';
import createUploadLink from 'apollo-upload-client/createUploadLink.mjs';

// Create the upload link
const uploadLink = createUploadLink({
  uri: 'https://api.enforceapp.com/graphql',
  //  uri: 'http://localhost:3000/graphql',
});

const authLink = setContext((_, { headers }) => {
  return {
    headers: {
      ...headers,
      'x-apollo-operation-name': 'your-operation-name',
      'apollo-require-preflight': 'true',
      'Content-Type': 'application/json',
    },
  };
});

const link = ApolloLink.from([authLink, uploadLink]);

const client = new ApolloClient({
  link,
  cache: new InMemoryCache(),
});

export default client;
