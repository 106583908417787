import React from 'react';
import clsx from 'clsx';
import { AiOutlineLoading } from 'react-icons/ai';

const Button = (props) => {
 const {
  border = 'primary',
  name,
  onClick,
  svg,
  svgBefore = false,
  variant = 'primary',
  size,
  type = 'button',
  halfWidth,
  loading,
  isDisabled = loading ?? false,
  fullWidth = false,
  customClass,
 } = props;

 return (
  <button
   type={type}
   disabled={isDisabled}
   onClick={onClick}
   className={clsx(
    `flex-shrink-0 outline-none ${
     loading ? 'flex justify-center items-center' : ''
    } ${customClass}`,
    {
     // Size styles - Always applied
     'py-[12px] px-[16px] text-base font-medium': size === 'large',
     'py-[10px] px-[36px] text-sm font-medium': size === 'medium',
     'py-[8px] px-[36px] text-xs font-bold': size === 'small',
     'py-[4px] px-[8px] text-xs font-normal': size === 'extraSmall',

     // Variant styles (enabled state)
     'bg-black primaryButton border border-black hover:border-black text-white hover:text-black hover:bg-white focus:active:bg-black focus:active:bg-opacity-85 focus:active:border-black focus:active:border-opacity-90 focus:active:text-white':
      variant === 'primary' && !isDisabled,
     'bg-white secondaryButton border border-black text-black hover:border-gray08 hover:text-black focus:active:text-white focus:active:border-gray08':
      variant === 'secondary' && !isDisabled,
     'tertiaryButton border border-white text-primary600 hover:text-primary700 hover:border hover:border-neutral100 hover:hover:bg-neutral100 focus:active:border focus:active:border-neutral100 focus:active:text-primary800':
      variant === 'tertiary' && !isDisabled,

     // Disabled state styles (Keep the same padding)
     'bg-black border border-[rgba(0,0,0,0.1)] text-white opacity-50':
      variant === 'primary' && isDisabled,
     'bg-white secondaryButton border border-primary500 opacity-50 text-primary500':
      variant === 'secondary' && isDisabled,
     'border border-white text-primary600 opacity-50 hover:text-primary700':
      variant === 'tertiary' && isDisabled,

     // Border styles
     'rounded m-0': border === 'secondary',
     'rounded-full': border === 'full',
     'rounded-[4px]': border === 'primary',

     // Width styles
     'w-full': fullWidth,
     'md:w-1/2': halfWidth,
    },
   )}
  >
   {loading ? (
    <AiOutlineLoading
     className={`h-5 w-5 animate-spin ${
      variant === 'primary' ? 'text-white' : 'text-primary500'
     }`}
    />
   ) : (
    <div className="flex flex-row items-center gap-2 justify-center">
     {svgBefore && svg}
     {name}
     {!svgBefore && svg}
    </div>
   )}
  </button>
 );
};

export default Button;
