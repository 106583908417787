import React, { useState } from 'react';
import { Car, Card, CardOff } from '../assets';
import { PERMIT_PAYMENT_PENDING, PERMIT_STATUS_COLORS } from '../util/constants';
import Button from './Button';
import { useNavigate, useParams } from 'react-router-dom';
import { useMutation } from '@apollo/client';
import { CANCEL_SUBSCRIPTION } from '../apis';
import Modal from './Modal';
import { formatDate, formatStatus } from '../util/helperFunctions';
import { Toasts } from './Toasts';

const VehicleDetailCard = ({ permit, email, refetch }) => {
  const [isOpen, setIsOpen] = useState(false);
  const { municipalityName } = useParams();
  const navigate = useNavigate();
  const [cancelSubscription, { loading }] = useMutation(CANCEL_SUBSCRIPTION);
  const showPaymentButton = PERMIT_PAYMENT_PENDING.includes(permit?.permitStatus);
  const showCanceSubscriptionButton =
    permit?.permitStatus === 'ACTIVE' && !!permit?.subscriptionId && !permit?.subscriptionCanceled;

  const handleSubscriptionCancel = async (subscriptionId) => {
    try {
      const response = await cancelSubscription({
        variables: {
          subscriptionId,
        },
      });
      Toasts({ variant: 'success', message: 'Subscription Cancelled' });
      setIsOpen(false);
      refetch && refetch();
      console.log(response?.data);
    } catch (error) {
      console.log(error);
    }
  };
  const handleNavigation = () => {
    navigate(`/${municipalityName}/pay-permit?id=${permit?.id}&email=${email}`);
  };
  return (
    <div className="flex flex-1 flex-col max-w-[323px] md:max-w-[230px] min-w-[222px] bg-black bg-opacity-5 p-9 md:p-4 ">
      <div className="flex-1">
        <div>
          <div className="flex items-center gap-2">
            <div className="min-w-4 min-h-4">
              <Car />
            </div>
            <p className="text-xs font-semibold tracking-[2px] ">
              {permit.color || permit.make || permit.modal
                ? `${permit.color} ${permit.make} ${permit.modal}`
                : '---'}
            </p>
          </div>
          <div className="flex flex-col gap-6 md:gap-2 py-6 md:py-4">
            <div className="flex  justify-between gap-2 ">
              <span className="text-xs text-black text-opacity-50 min-w-[110px]">
                License Plate:
              </span>
              <span className="flex-1 text-xs text-black text-left">
                {`${permit?.licensePlateState} ${permit?.licensePlateNumber}`}
              </span>
            </div>
            <div className="flex flex-col justify-between gap-2 ">
              <span className="text-xs flex-1 text-black text-opacity-50 min-w-[110px]">
                Address:
              </span>
              <span className="flex-1 text-xs text-black text-left">
                {`${permit?.ownerAddress}, ${permit?.ownerCity}, ${permit?.ownerState}, ${permit?.ownerZip}`}
              </span>
            </div>
          </div>
        </div>
        <div className="border-b"></div>
        <div className="py-6 md:pb-4">
          <div className="flex  items-start">
            <p className="text-xs font-bold tracking-[2px]">Parking permit:</p>
          </div>
          <div className="flex flex-col gap-6 md:gap-2 pt-6 md:pt-2">
            <div className="flex flex-wrap  justify-between gap-2 ">
              <span className="text-xs  text-black text-opacity-50  min-w-[62px]">Status:</span>
              <div className="flex-row items-center flex flex-1 ">
                <div
                  className="h-2 w-2 rounded-full mr-[10px] self-start mt-1"
                  style={{
                    backgroundColor: PERMIT_STATUS_COLORS[permit?.permitStatus],
                  }}
                />
                <span className={`text-xs text-left min-w-[62px] `}>
                  {formatStatus(permit?.permitStatus)}
                </span>
              </div>
            </div>
            <div className="flex flex-wrap  justify-between gap-2 ">
              <span className="text-xs  text-black text-opacity-50  min-w-[62px]">Issued:</span>
              <span className="flex-1 text-xs text-black text-left min-w-[62px] ">
                {formatDate(permit?.effectiveDate)}
              </span>
            </div>
            <div className="flex flex-wrap  justify-between gap-2 ">
              <span className="text-xs  text-black text-opacity-50  min-w-[62px]">Expires:</span>
              <span className="flex-1 text-xs text-black text-left min-w-[62px] ">
                {formatDate(permit?.expirationDate)}
              </span>
            </div>
            {showCanceSubscriptionButton && (
              <>
                <div className="flex flex-wrap  justify-between gap-2 ">
                  <span className="text-xs  text-black text-opacity-50  min-w-[62px]">
                    Last Payment:
                  </span>
                  <span className="flex-1 text-xs text-black text-left min-w-[62px] ">
                    {formatDate(permit?.lastPayment)}
                  </span>
                </div>
                <div className="flex flex-wrap  justify-between gap-2 ">
                  <span className="text-xs  text-black text-opacity-50  min-w-[62px]">
                    Renews On:
                  </span>
                  <span className="flex-1 text-xs text-black text-left min-w-[62px] ">
                    {formatDate(permit?.nextPayment)}
                  </span>
                </div>
              </>
            )}
          </div>
        </div>
      </div>
      <div>
        {showPaymentButton && (
          <Button
            className="self-end"
            name={'Pay My Permit'}
            onClick={handleNavigation}
            size="small"
            fullWidth={true}
            variant="primary"
            svg={<Card />}
            svgBefore={true}
          />
        )}
        {showCanceSubscriptionButton && (
          <Button
            name={'Cancel Renewal'}
            onClick={() => setIsOpen(true)}
            size="small"
            fullWidth={true}
            variant="primary"
            svg={<CardOff />}
            svgBefore={true}
          />
        )}
      </div>
      <Modal isOpen={isOpen}>
        <div>
          <span className="text-lg font-bold flex flex-row justify-center">Cancel Renewal?</span>
          <div className="text-center mx-10 mt-9 text-base text-black text-opacity-50">
            Are you sure you want to cancel this renewal? If cancelled, it will stop being valid on{' '}
            <span className="font-bold text-black">{formatDate(permit?.nextPayment)}</span>
          </div>
          <div className="border-b my-9 border-black border-opacity-10"></div>
          <div className="flex flex-row gap-4">
            <div className="flex flex-1">
              <Button
                name={'Back'}
                onClick={() => setIsOpen(false)}
                size="small"
                fullWidth={true}
                variant="secondary"
              />
            </div>
            <div className="flex flex-1">
              <Button
                loading={loading}
                name={'Cancel Renewal'}
                onClick={() => handleSubscriptionCancel(permit?.subscriptionId)}
                size="small"
                fullWidth={true}
                variant="primary"
                svg={<CardOff />}
                svgBefore={true}
              />
            </div>
          </div>
        </div>
      </Modal>
    </div>
  );
};

export default VehicleDetailCard;
