const Submit = () => (
 <svg
  className="car-icon"
  width="13"
  height="14"
  viewBox="0 0 13 14"
  fill="currentColor" // This allows CSS to control the color
  xmlns="http://www.w3.org/2000/svg"
 >
  <path
   d="M2.42188 11.3776V2.71094L11.9219 7.04427L2.42188 11.3776ZM3.42188 9.7526L9.34688 7.04427L3.42188 4.33594V6.23177L6.42188 7.04427L3.42188 7.85677V9.7526Z"
   fill="currentColor" // This allows CSS to control the color
  />
 </svg>
);

export default Submit;
