import React from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { PRIVACY_POLICY_URL } from '../util/constants';

const Footer = () => {
 const { municipalityName } = useParams();
 const navigate = useNavigate();
 const path = window.location.pathname;

 return (
  <div
   className={`py-8 bg-[#FAFAFA] absolute w-full  ${
    path === '/contact-us' && 'bottom-0'
   }`}
  >
   <div className="flex flex-col justify-center items-center gap-4">
    <div className="flex justify-center items-center ">
     <p
      onClick={() => window.open(PRIVACY_POLICY_URL, '_blank')}
      className="text-sm font-bold text-right border-r pr-4 hover:underline cursor-pointer"
     >
      Privacy Policy
     </p>
     <p
      className="text-sm font-bold text-left pl-4 hover:underline cursor-pointer"
      onClick={() => navigate(`/${municipalityName}/contact-us`)}
     >
      Contact Us
     </p>
    </div>
    <div>
     <p className="text-sm text-black text-opacity-50">
      © 2024 Ticket Online Pay
     </p>
    </div>
   </div>
  </div>
 );
};

export default Footer;
