import React, { useCallback, useEffect, useState } from 'react';

import LogoWithAddress from '../components/LogoWithAddress';
import { Back } from '../assets';
import Accordion from '../components/Accordion';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import { gql, useLazyQuery } from '@apollo/client';
import { ClipLoader } from 'react-spinners';
export const GET_CITATION_PERMITS = gql`
  query GetCitationPermits(
    $citationNumber: Float
    $licensePlateState: String
    $licensePlateNumber: String
  ) {
    getCitationPermits(
      getCitationPermitInput: {
        citationNumber: $citationNumber
        licensePlateState: $licensePlateState
        licensePlateNumber: $licensePlateNumber
      }
    ) {
      vehicleData
      citations {
        id
        color
        description
        licensePlateNumber
        licensePlateState
        make
        modal
        photo
        date
        time
        fineAmount
        location
        locationResult
        citationNumber
        citationStatus
        userId
        violationId
        violation {
          code
          title
        }
        municipalityId
        municipality {
          id
          name
          logo
        }
        createdAt
        updatedAt
      }
      permits {
        id
        permitNumber
        color
        licensePlateNumber
        licensePlateState
        make
        modal
        fee
        ownerCity
        ownerAddress
        ownerName
        term
        ownerZip
        ownerState
        ownerDOB
        effectiveDate
        expirationDate
        permitStatus
        municipalityId
        municipality {
          id
          name
          logo
        }
        parkingPermitId
        createdAt
        updatedAt
      }
    }
  }
`;
const TicketDetails = () => {
  const location = useLocation();
  const { municipalityName } = useParams();
  const queryParams = new URLSearchParams(location.search);
  const citationNumber = queryParams.get('citation-number');
  const state = queryParams.get('state');
  const licensePlate = queryParams.get('license-plate');
  const [apiData, setApiData] = useState();
  const [loading, setLoading] = useState();
  const [isOpen, setIsOpen] = useState(null);
  const [getCitationPermits] = useLazyQuery(GET_CITATION_PERMITS, {
    fetchPolicy: 'network-only',
  });
  const [municipality, setMunicipality] = useState();

  const handleSearchData = useCallback(async () => {
    try {
      setLoading(true);
      const { data } = await getCitationPermits({
        variables: {
          citationNumber: Number(citationNumber) || null,
          licensePlateState: state || null,
          licensePlateNumber: licensePlate || null,
        },
      });

      const searchUrl = `${location.pathname}${location.search}`;
      localStorage.setItem('searchUrl', searchUrl);
      setApiData(data?.getCitationPermits);
      if (data?.getCitationPermits?.citations?.[0]) {
        setMunicipality(data?.getCitationPermits?.citations?.[0]?.municipality);
      }
    } catch (error) {
      console.log('>>>', error);
    } finally {
      setLoading(false);
    }
  }, [citationNumber, getCitationPermits, licensePlate, location.pathname, location.search, state]);
  useEffect(() => {
    handleSearchData();
  }, [state, licensePlate, citationNumber, handleSearchData]);

  const navigate = useNavigate();
  return loading ? (
    <div className="flex flex- h-screen justify-center items-center">
      <ClipLoader />
    </div>
  ) : apiData?.citations.length > 0 ? (
    <>
      <div className="flex flex-col justify-start items-center h-[calc(100vh-121px)] overflow-auto">
        <LogoWithAddress municipality={municipality} showDetail={false} />
        <div className="md:w-[704px] pb-11">
          <div className="border-b my-8"></div>
          <div className="border">
            <div className=" hidden md:flex justify-between items-center bg-black bg-opacity-5 px-4 py-2 text-wrap">
              <p className="w-[130.63px] text-sm font-bold text-black text-opacity-50">Date/Time</p>
              <p className="w-[167px] text-sm font-bold text-black text-opacity-50">Vehicle</p>
              <p className="w-[124px] text-sm font-bold text-black text-opacity-50">Violation</p>
              <p className="w-[180px] text-sm font-bold text-black text-opacity-50">Status</p>
              <p className="w-[48px] text-sm font-bold text-black text-opacity-50">Owed</p>
            </div>
            {apiData?.citations?.map((item) => (
              <Accordion
                isOpen={isOpen}
                setIsOpen={setIsOpen}
                key={item.id}
                data={item}
                content={'item.details'}
              />
            ))}
          </div>
        </div>
      </div>
      <div className="fixed top-0 left-0 cursor-pointer w-screen md:w-fit bg-[#FAFAFA] py-[37px] px-[30px]">
        <div
          className="flex justify-start items-center gap-[10px]"
          onClick={() => navigate(`/${municipalityName}/search-citation`)}
        >
          <Back />
          <p className="font-bold">Back</p>
        </div>
      </div>
    </>
  ) : (
    <div className="h-[calc(100vh-121px)] flex flex-1 justify-center items-center">
      <div className="fixed top-0 left-0 cursor-pointer w-screen md:w-fit bg-[#FAFAFA] py-[37px] px-[30px]">
        <div
          className="flex justify-start items-center gap-[10px]"
          onClick={() => navigate(`/${municipalityName}/search-citation`)}
        >
          <Back />
          <p className="font-bold">Back</p>
        </div>
      </div>
      No Data found
    </div>
  );
};

export default TicketDetails;
