import React from 'react';

const Car = ({ height = '16', width = '14' }) => {
 return (
  <svg
   width={width}
   height={height}
   viewBox="0 0 14 16"
   fill="none"
   xmlns="http://www.w3.org/2000/svg"
  >
   <g clip-path="url(#clip0_98_3495)">
    <path
     d="M10.8428 2.06579C10.707 1.66511 10.3267 1.37988 9.87846 1.37988H2.40816C1.95994 1.37988 1.58643 1.66511 1.44382 2.06579L0.03125 6.13371V11.5666C0.03125 11.9402 0.336853 12.2458 0.710368 12.2458H1.38949C1.763 12.2458 2.0686 11.9402 2.0686 11.5666V10.8875H10.218V11.5666C10.218 11.9402 10.5236 12.2458 10.8971 12.2458H11.5763C11.9498 12.2458 12.2554 11.9402 12.2554 11.5666V6.13371L10.8428 2.06579ZM2.64585 2.73812H9.63398L10.3674 4.85017H1.91241L2.64585 2.73812ZM10.8971 9.5293H1.38949V6.13371H10.8971V9.5293Z"
     fill="black"
    />
    <path
     d="M3.08508 8.84888C3.64768 8.84888 4.10376 8.3928 4.10376 7.8302C4.10376 7.2676 3.64768 6.81152 3.08508 6.81152C2.52248 6.81152 2.06641 7.2676 2.06641 7.8302C2.06641 8.3928 2.52248 8.84888 3.08508 8.84888Z"
     fill="black"
    />
    <path
     d="M9.19836 8.84888C9.76096 8.84888 10.217 8.3928 10.217 7.8302C10.217 7.2676 9.76096 6.81152 9.19836 6.81152C8.63576 6.81152 8.17969 7.2676 8.17969 7.8302C8.17969 8.3928 8.63576 8.84888 9.19836 8.84888Z"
     fill="black"
    />
   </g>
   <defs>
    <clipPath id="clip0_98_3495">
     <rect
      width="14"
      height="15"
      fill="white"
      transform="translate(0 0.0439453)"
     />
    </clipPath>
   </defs>
  </svg>
 );
};

export default Car;
